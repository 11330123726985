import {
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import Wrapper from '@/components/elements/Wrapper'
import Nav from '@/components/elements/navigation/Nav'
import { style } from '@/styles/global'
import { gql, useQuery } from '@apollo/client'

// Define a GraphQL query
const GET_PAGES = gql`
  query GetPages {
    pages {
      edges {
        node {
          myrphey {
            unit {
              name
              number
            }
          }
        }
      }
    }
  }
`

type Unit = {
  name: string
  number: string
}

type Myrphey = {
  unit: Unit | null
}

type Node = {
  myrphey: Myrphey | null
}

type Edge = {
  node: Node
}

type PagesData = {
  pages: {
    edges: Edge[]
  }
}

const renderUnit = (node: Node, index: number) => {
  const unit = node.myrphey?.unit
  if (!unit || !unit.number) return null

  return (
    <ListItem key={index}>
      <ListItemText
        primary={`Unit ${unit.number}`}
        secondary={
          <Typography
            component="span"
            dangerouslySetInnerHTML={{ __html: unit.name }}
          />
        }
      />
    </ListItem>
  )
}

const Books = () => {
  const { loading, error, data } = useQuery<PagesData>(GET_PAGES)

  if (error) return <p>Error: {error.message}</p>

  const edges = data?.pages?.edges || []

  return (
    <>
      <Nav />
      <Wrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 60 }}
        >
          <Typography variant="h5">Books</Typography>
        </Stack>
        {loading ? (
          <List>
            <ListItem>
              <ListItemText>
                <Skeleton animation="wave" width={60} />
                <Skeleton animation="wave" width={500} />
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Skeleton animation="wave" width={60} />
                <Skeleton animation="wave" width={500} />
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Skeleton animation="wave" width={60} />
                <Skeleton animation="wave" width={500} />
              </ListItemText>
            </ListItem>
          </List>
        ) : (
          <List>{edges.map(({ node }, index) => renderUnit(node, index))}</List>
        )}

        <style.Content></style.Content>
      </Wrapper>
    </>
  )
}

export default Books

// {
//   pages {
//     nodes {
//       myrphey {
//         exercises {
//           exerciseChooseCorrectForm {
//             answers {
//               answer
//             }
//             description
//             number
//             tasks {
//               task {
//                 answer
//                 question
//               }
//             }
//           }
//           exerciseChooseFollowingVerbs {
//             answers {
//               answer
//             }
//             description
//             number
//             tasks {
//               task {
//                 answer
//                 question
//               }
//             }
//           }
//           selectExercise
//         }
//         unit {
//           name
//           number
//         }
//       }
//     }
//   }
// }
