import { useState } from 'react'
import { Button, Menu, Stack } from '@mui/material'
import { useAppDispatch } from '@/shared/redux/hooks'
import { logout } from '@/shared/redux/reducers/authSlice'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import { style } from '@/styles/global'

const AppMenu: React.FC = () => {
  const dispatch = useAppDispatch()
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <>
      <Button
        color="primary"
        size="small"
        variant="outlined"
        style={style.button.bold}
        onClick={() => history.navigate(routes.words)}
      >
        Words
      </Button>
      <Button
        color="primary"
        size="small"
        variant="outlined"
        style={style.button.bold}
        onClick={() => history.navigate(routes.collections)}
      >
        Collections
      </Button>
      <Button
        color="primary"
        size="small"
        variant="outlined"
        style={style.button.bold}
        onClick={() => history.navigate(routes.learn)}
      >
        Learn
      </Button>
      <Button
        color="primary"
        size="small"
        variant="outlined"
        style={style.button.bold}
        onClick={() => history.navigate(routes.books)}
      >
        Books
      </Button>
      {/* desktop */}
      <Button
        sx={{ display: { sm: 'none' } }}
        color="primary"
        size="small"
        variant="outlined"
        style={style.button.bold}
        onClick={() => history.navigate(routes.profile)}
      >
        Profile
      </Button>
      <Button
        sx={{ display: { sm: 'none' } }}
        color="primary"
        size="small"
        variant="outlined"
        style={style.button.bold}
        onClick={() => dispatch(logout())}
      >
        Logout
      </Button>
      {/* mobile */}
      <Stack sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          style={style.button.bold}
          onClick={handleOpenUserMenu}
        >
          Profile
        </Button>
        <Menu
          sx={style.submenu.main}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <Stack>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              sx={style.submenu.button}
              onClick={() => history.navigate(routes.profile)}
            >
              Account Details
            </Button>
          </Stack>
          <Stack sx={{ mt: 1 }}>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              sx={style.submenu.button}
              onClick={() => dispatch(logout())}
            >
              Logout
            </Button>
          </Stack>
        </Menu>
      </Stack>
    </>
  )
}

export default AppMenu
