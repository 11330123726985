import { Box, Button, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { history } from '@utils/history'
import routes from '@/pages/routes'
import Wrapper from '@/components/elements/Wrapper'
import Nav from '@/components/elements/navigation/Nav'
// import Pricing from '@/components/pricing/Pricing'
import { style } from '@/styles/global'
import { typography } from '@/styles/typography'

const Learn = () => {
  return (
    <>
      <Nav />
      <Wrapper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ height: 60 }}
        >
          <Typography variant="h5">Learn</Typography>
        </Stack>
        <style.Content>
          <style.Heading component={motion.div} {...style.fadeInUp}>
            <Typography sx={{ color: 'text.secondary', mb: 3 }}>
              Choose your learning option:
            </Typography>
          </style.Heading>
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 60 }}
            animate={style.fadeInUp.animate}
          >
            <Button
              sx={{ color: typography.colors.secondary }}
              fullWidth
              size="small"
              variant="outlined"
              style={style.button.bold}
              onClick={() => history.navigate(routes.flashCardOptions)}
            >
              Flash Cards
            </Button>
          </Box>
        </style.Content>

        {/* <Pricing /> */}
      </Wrapper>
    </>
  )
}

export default Learn
